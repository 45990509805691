@import '../../styles/propertySets.css';

/* Desktop */
.rootNarrow,
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  /* display: flex; */
  /* flex-direction: row; */
  /* justify-content: space-between; */
  /* align-items: flex-start; */
  /* padding: 0 12px 0 0; */

  /* fill */
  /* background-color: var(--matterColorLight); */

  /* shadows */
  box-shadow: var(--boxShadowLight);
  padding: 0;
}

.root {
  background-color: white;
  padding: 0;
}

.searchAlt {
  flex: 1;
}

.rootInner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 28px 6px 28px;
  background-color: white;
  border-bottom: 1px solid var(--gray-200);
}

.rootInnerRightMenu {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.rootInnerLeftMenu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

/* logo */
.logoLink {
  /* @apply --TopbarDesktop_linkHover; */
  flex-shrink: 0;
  padding: 16px 24px 16px 24px;
  height: 100%;
  align-items: center;
  display: flex;

  @media (--viewportLarge) {
    padding: 16px 36px 16px 36px;
  }
}

.logo {
  /* If height is increased, change link paddings accordingly */
  height: 27px;
  transform: scale(2);

  /* Good aspect ratio should be less than 160/27 (width/height) */
  /* NOTE: extremely wide logos, need special handling. */
  width: auto;
  max-width: 220px;
  object-fit: contain;
  object-position: left center;
}

/* Search */
.searchLink {
  flex-grow: 1;
  height: 100%;
  margin-right: 24px;

  @media (--viewportLarge) {
    padding-left: 0;
  }
}

.search {
  @apply --marketplaceH4FontStyles;
  @apply --TopbarDesktop_label;
  color: var(--matterColor);
}

.topbarSearchWithLeftPadding {
  padding-left: 24px;
  /* height: var(--topbarHeightDesktop); */

  @media (--viewportLarge) {
    padding-left: 12px;
  }

  @media (min-width: 1440px) {
    padding-left: 32px;
  }
}

/* Create listing (CTA for providers) */
.servicesLink,
.helpLink,
.contactLink,
.createListingLink,
.storeLink {
  @apply --TopbarDesktop_linkHover;
  @apply --marketplaceH5FontStyles;

  flex-shrink: 0;
  padding: 0 12px 0 12px;
  color: #475467;
  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: black;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.storeLink {
  padding-right: 0;
}

.servicesLinkLight,
.helpLinkLight,
.contactLinkLight,
.createListingLinkLight,
.storeLinkLight {
  @apply --TopbarDesktop_linkHover;
  @apply --marketplaceH5FontStyles;

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  /* color     : var(--marketplaceColor); */
  color: var(--matterColorLight);
  margin-top: 0;
  margin-bottom: 0;

  /* &:hover {
    color: var(--marketplaceColorDark);
  } */

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.createListing {
  @apply --TopbarDesktop_label;
}

.goToStore {
  @apply --TopbarDesktop_label;
  border: 1px solid var(--marketplaceColor);
  border-radius: 30px;
  font-size: 15px;
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  padding: 15px 30px;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }
}

.createListingLink2 {
  display: flex;
  align-items: center;
  height: 100%;
  outline: inherit;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
}

.primaryButton {
  font-size: 16px;
  border: 1px solid var(--brand-500);
  background-color: var(--brand-500);
  font-weight: var(--fontWeightSemiBold);
  border-radius: 8px;
  height: 42px;
  padding: 14px 20px 14px 20px;
  color: var(--matterColorLight);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.primaryOutlineButton {
  font-size: 16px;
  border: 1px solid var(--gray-300);
  background-color: white;
  font-weight: var(--fontWeightSemiBold);
  border-radius: 8px;
  height: 42px;
  padding: 14px 20px 14px 20px;
  color: var(--primary700);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-transform: uppercase
}

/* Inbox */
.inboxLinkLight,
.inboxLink {
  @apply --TopbarDesktop_linkHover;
  @apply --marketplaceH5FontStyles;
  display: flex;
  height: 100%;
  align-items: center;
  padding: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  &:hover > span {
    box-shadow: var(--boxShadowButton);
  }
}

.inboxLink {
  color: var(--matterColorLight);

  &:hover {
    /* color: var(--matterColorDark); */
  }
}

.inboxLinkLight {
  color: var(--matterColorLight);

  &:hover {
    /* color: var(--matterColorDark); */
  }
}

.inbox {
  @apply --TopbarDesktop_label;
  position: relative;
  background-color: var(--marketplaceColor);
  font-size: 25px;
  padding: 8px 45px 8px 45px;
  margin: 0px;
  border-radius: 1963px;
}

.notificationDot {
  /* Dimensions */
  width: 8px;
  height: 8px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: calc(50% - 4px);
  right: 10px;

  /* Style: red dot */
  background-color: var(--failColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  @apply --TopbarDesktop_linkHover;
  font-size: 16px;
  border: 1px solid var(--brand-500);
  background-color: white;
  font-weight: var(--fontWeightSemiBold);
  border-radius: 8px;
  height: 42px;
  padding: 14px 20px 14px 20px;

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 16px 0;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}

/* Authentication */
.signupLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--matterColor);

  &:hover {
    color: var(--matterColorDark);
  }
}

.loginLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--matterColor);

  &:hover {
    color: var(--matterColorDark);
  }
}

.signupLinkLight {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--matterColorLight);
}

.loginLinkLight {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--matterColorLight);
}

.signup,
.login {
  @apply --marketplaceH5FontStyles;
  @apply --TopbarDesktop_label;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  @apply --marketplaceH4FontStyles;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--brand-500);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  @apply --marketplaceLinkStyles;
  /* Font is specific to this component */
  @apply --marketplaceH5FontStyles;
  font-size: 14px;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--matterColorAnti);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--matterColorNegative);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--brand-500);
    }
  }

  @media (--viewportMedium) {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--matterColorDark);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--brand-500);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--brand-500);
    }
  }
}

.leftMenu {
  margin-right: 24px;
  white-space: nowrap;
  display: flex;
  gap: 24px;
}

.leftMenuItem {
  font-size: 14px;
  padding: 0 6px 0 6px;
}

.selectedLangLabel {
  width: 80px;
  height: 44px;
  border-radius: 8px;
  padding: 14px 20px 14px 20px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.langOptionLink {
  @apply --marketplaceH4FontStyles;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}
