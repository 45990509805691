.root {
  fill: var(--matterColor);
  transition: var(--transitionStyleButton);
  margin-top: 10px;

  @media (max-width: 768px) {
    margin-top: 0px;
  }

  &:hover {
    fill: var(--marketplaceColor);
  }
}
