.navbarRoot {
  width: 100%;
  height: 124px;
  border: 0;
  border-bottom: 1px solid var(--gray-200);
  overflow-y: hidden;

  @media (--viewportSmall) {
    height: 106px;
  }
}
