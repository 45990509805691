@import '../../styles/propertySets.css';

.root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--matterColorBright);
}

.filtersWrapper {
}

.footer {
  display: flex;
  margin-top: 8px;
}

.resetAllButton {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0 auto 0 0;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}

.cancelButton {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);
  width: 150px;
  /* Layout */
  margin: 0;
  padding: 0.5rem 1rem;
  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}

.applyButton {
  @apply --marketplaceButtonStylesPrimary;
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);

  /* Layout */
  margin: 0 0 0 0.5rem;
  width: 150px;
  padding: 0.5rem 1rem !important;
  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--marketplaceColorDark);
  }
}
