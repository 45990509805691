@import '../../styles/propertySets.css';

.root {
  /* @apply --marketplaceModalFormRootStyles; */

  /* justify-content: flex-start; */

 
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.dob,
.telNum,
.password {
  @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
}

.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
}

.termsText {
  @apply --marketplaceModalHelperText;
  font-size: 12px;
  line-height: normal;
  padding: 24px;
}

.telNum {
  position: relative;
  width: 100%;
}

.termsLink {
  text-decoration: underline;
  color: black;
  &:hover {
    cursor: pointer;
  }
}

.phoneWrapper {
  position: relative;
  display: flex;
}

.phonePrefix {
  position: absolute;
  font-size: 16px;
  margin-bottom: 4px;
  top: 23px;

  @media (--viewportMedium) {
    top: 28px;
  }
}

.prefixField {
  width: 180px;
  margin-right: 10px;
}

.newsletterContainer {
  padding-top: 32px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  gap: 16px;
}

.newsletterText {
  @apply --marketplaceModalHelperText;
  font-size: 14px;
}

.privacyLink {
  /* color: var(--colorPrimary);   */
  text-decoration: underline;
  cursor: pointer;
}

.redStar {
  color: red;
}
