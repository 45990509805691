@import '../../styles/propertySets.css';
/* @import '../../forms/TopbarSearchForm/TopbarSearchForm.module.css'; */

.root {
  background-color: var(--matterColorLight);
  border-radius: var(--borderRadius);
  padding: 5px;

  @media (max-width: 768px) {
    background-color: transparent;
    padding: 8px;
    width: 100vw;
  }
}

.filterWrapper {
  display: flex;
  flex: 1;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.column {
  width: 50%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    display: none;
  }
}

.serviceWrapper {
  display: flex;
}

.cardWrapper {
  padding: 5px;
  flex: 1;
}

.serviceWrapper .cardWrapper .card,
.serviceWrapper .cardWrapper .cardWrapperSelected {
  padding: 10px 15px;
  border: 1px solid #d0d0d0;
  border-radius: var(--borderRadius);
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 66px;

  @media (max-width: 768px) {
    background-color: white;
    flex-direction: column;
    border: 0px;
  }
}

.card:hover {
  background: #e5e5e5;
}

.card:hover > .serviceIcon {
  margin-top: -5px;
  transition: 0.3s;
  filter: brightness(0);
}

.serviceWrapper .cardWrapper .cardWrapperSelected {
  border-color: var(--secondary-500);
  outline: 2px solid var(--secondary-200);
  background-color: var(--secondary-50);
}

.serviceIcon {
  height: 35px;
}

.serviceLabel {
  margin-left: 0.5rem;

  @media (max-width: 768px) {
    margin-left: 0px;
  }
}

.dropdownFilterWrapper {
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-right: 5px;
    margin-left: 5px;
    width: calc(100% - 10px);
  }
}

.dropdownFilter {
  border: 1px solid #d0d0d0;
  border-radius: var(--borderRadius);
  margin: 5px 5px;
  height: 66px;
  background-color: white;
  display: flex;
  align-items: center;
  flex-grow: 1;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.dropdownFilter .children {
  padding: 0px 15px;
  position: relative;
  flex-grow: 1;
  width: 100%;
}

.dropdownFilter label {
  font-weight: 400;
  font-size: 11px;
}

.dropdownFilter select {
  border-bottom-width: 0;
}

.dropdownFilter select option:first-child {
  color: #999999;
}

.dropdownFilterGroup {
  display: flex;
  align-items: center;
  width: 150px;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.dropdownFilterGroup .dropdownFilterIcon {
  padding-right: 8px;
}

.preferenceWrapper {
  width: 100%;
  cursor: pointer;
}

.selectPreference {
  margin: 0;
}

.preferenceDisplay {
  font-size: 14px;
  cursor: pointer;
  text-transform: capitalize;
}

.selectPreference {
  visibility: hidden;
  opacity: 0;
  background-color: var(--matterColorLight);
  position: absolute;
  transition: all 0.5s ease;
  margin-top: 5px;
  left: 0;
  display: none;
  width: 180px;
  cursor: pointer;
  border-radius: 10px;
  padding: 15px;
  border: 1px solid var(--marketplaceColor);
}

.preferenceOptions {
  font-size: 14px;
  padding-left: 4px;
}

.preferenceOptions:hover {
  border-left: 2px solid var(--marketplaceColor);
}

.showDropdown {
  visibility: visible;
  opacity: 1;
  background-color: var(--matterColorLight);
  position: absolute;
  transition: all 0.5s ease;
  margin-top: 5px;
  left: 0;
  display: block;
  width: 180px;
  cursor: pointer;
  border-radius: 10px;
  padding: 15px;
  z-index: 5;
  box-shadow: var(--boxShadowPopup);

  @media (max-width: 768px) {
    width: 100%;
  }
}

.closeWhenClickScreen {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  top: 0;
  left: 0;
}

.dateSelector {
  font-size: 14px;
  width: 100%;
  min-width: none;
}

.noborder {
  border: 0px;
}

.searchButtonWrapper {
  padding-left: 10px;
  padding-right: 10px;
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.columnMobile {
  display: none;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.primaryButton {
  min-height: 45px !important;
  height: 57px;
  font-size: 15px;
  background-color: var(--brand-500);

  @media (max-width: 768px) {
    margin-top: 8px;
  }
}

.container {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: var(--boxShadow);

  @media (--viewportMedium) {
    display: none;
  }
}

.home {
  display: flex;
  padding: calc((var(--topbarHeight) - var(--Topbar_logoHeight)) / 2) 24px;
}

.logoutButton {
  margin: 0;
}

/* Child component's size (and positioning) should be given here */
.menu,
.searchMenu {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);

  /* Layout */
  display: flex;
  width: 66px;
  margin: 0;
  border-width: 0px;
  background-color: transparent;

  /* Hovers */
  &:enabled {
    cursor: pointer;
  }

  &:enabled:hover,
  &:enabled:active {
    background-color: transparent;
    box-shadow: none;
  }

  &:disabled {
    background-color: transparent;
    cursor: auto;
  }
}

.menu {
  padding: 15px 24px;
  position: relative;
}

.searchMenu {
  padding: 12px 24px;
}

.menuIcon,
.searchMenuIcon {
  margin: 9px 0;
}

.notificationDot {
  /* Dimensions */
  width: 12px;
  height: 12px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 17px;
  right: 17px;

  /* Style: red dot with white border */
  background-color: var(--failColor);
  border: 1px solid var(--matterColorLight);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.desktop {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

/* Icon component styles */
.rootMenuIcon {
  fill: var(--matterColor);
}

.rootSearchIcon {
  stroke: var(--matterColor);
}

.mobileHelp {
  color: var(--matterColorAnti);
  margin: 0 24px;

  /* Absolute position to avoid affecting the layout of the autocomplete
   predictions list */
  position: absolute;
  top: 73px;
}

/* ================ Modal ================ */

.modalContainer {
  @apply --marketplaceModalInMobileBaseStyles;
  padding: 0;

  @media (--viewportMedium) {
    flex-basis: 576px;
  }
}

.missingInformationModal {
  @apply --marketplaceModalBaseStyles;
  padding-top: 70px;
}

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
}

.helperLink {
  @apply --marketplaceModalHelperLink;
}

.helperText {
  @apply --marketplaceModalHelperText;
}

.error {
  @apply --marketplaceModalErrorStyles;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.modalIcon {
  @apply --marketplaceModalIconStyles;
}

.reminderModalLinkButton {
  @apply --marketplaceButtonStylesPrimary;
}

.genericError {
  position: fixed;
  top: calc(var(--topbarHeight) + 15px);

  /* Place enough to the right to hide the error */
  right: -355px;
  opacity: 0;

  max-width: 340px;
  margin: 0 15px;

  /* Animate error from the right */
  transition: all ease-out 0.2s;
  transition-delay: 1s;

  /* Bleed the bg color from the bottom */
  padding-bottom: 4px;
  background-color: var(--marketplaceColor);
  border-radius: 4px;

  /* Place on top of everything */
  z-index: var(--zIndexGenericError);

  @media (--viewportMedium) {
    top: calc(var(--topbarHeightDesktop) + 15px);
  }
}

.genericErrorVisible {
  right: 0;
  opacity: 1;
}

.genericErrorContent {
  padding: 16px 22px;
  border-radius: 4px;
  background-color: var(--marketplaceColorLight);
  border-radius: 4px;
}

.genericErrorText {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  color: var(--matterColorLight);

  @media (--viewportMedium) {
    margin: 0;
  }
}
