.root {
  width: 100%;
  overflow-y: hidden;

}

.rootInner {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
}
