@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);
  border-radius: var(--borderRadius);
  overflow: hidden;
  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  }
}

.shadowOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 40%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: 0;
  border-radius: var(--borderRadius);
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%;
  /* 3          :2 Aspect Ratio */
  background: var(--matterColorNegative);
  /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: var(--borderRadius);
}

.info {
  /* Layout */
  position: absolute;
  bottom: 2px;
  left: 10px;
  display: flex;
  flex-direction: row;
  padding: 4px;
  z-index: 10;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 18px;
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.authorInfo {
  /* Font */
  color: #ffffff;
  font-size: 20px;
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.reviewWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.review {
  margin-bottom: -3px;
  margin-left: 0.5rem;
  color: #ffffff;
  font-size: 18px;
}

.profileImageWrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  padding: 4px;
  z-index: 0;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.avatar {
  border: 2px solid white;
  width: 7vw;
  height: 7vw;

  @media (max-width: 1024px) {
    width: 10vw;
    height: 10vw;
  }

  @media (max-width: 768px) {
    width: 20vw;
    height: 20vw;
  }
}

.priceWrapper {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
}

.priceContainer {
  background-color: var(--marketplaceColor);
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: white;
}

.price {
  margin: 0px;
  font-size: 18px;
}

.unit {
  font-weight: 200;
  margin-top: -8px;
  font-size: 14px;
}

.reviewsCount {
  margin-left: 6px;
  color: #FFF;
  font-size: 13px;
  display: flex;
  align-items: center;
  line-height: 24px;
  margin-top: 3px;
}

.responseTime {
  color: #FFF;
  font-size: 12px;
  display: flex;
  align-items: center;
  & img {
    height: 18px;
    width: 18px;
    margin-right: 4px;
  }
}