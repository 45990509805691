.root {
}

.input {
  border-bottom-color: var(--attentionColor);
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
}

.counter {
  text-align: end;
  margin-top: 4px;
}

.counterValid {
  color: #1ead52;
}