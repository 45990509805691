.container {
  position: relative;
}

.container:hover {
  outline: 1px solid var(--gray-300);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.headerWrapper {
  position: relative;
}

.header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.mainMenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  z-index: 10;
  padding: 4px 0 4px 0;
}

.headerWrapper:hover .mainMenu {
  display: block;
}

.menu {
  list-style: none;
  padding: 0;
  margin: 0;
  border-radius: 8px;
}

.item {
  position: relative;
}

.link {
  text-decoration: none !important;
  color: var(--matterColorDark);
}

.itemContent {
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  border-radius: 8px; /* Ensures the hover effect respects the rounded corners */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth hover transition */
  margin: 0 4px 0 4px;
}

.itemContent:hover {
  background-color: var(--gray-200); /* Light gray hover background */
}

.subMenuWrapper {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  z-index: 90;
  border-radius: 8px;
  max-height: 60vh;
  overflow-y: auto;
}

.item:hover > .subMenuWrapper {
  display: block;
}

.container:has(.mainMenu:hover, .item:hover) {
  outline: 1px solid var(--gray-300);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
