.root {
}

.serviceFieldSelect {
  /* @media (--viewportMedium) {
    width: calc(100vw - 48px);
    margin: 0 24px 3px 24px;
  } */
}

.root label {
  font-size: 15px;
}

.select {
  color: var(--matterColorAnti);
  border-bottom-color: var(--attentionColor);
  padding-right: 20px;
  margin-bottom: 20px;

  & > option {
    color: var(--matterColor);
  }
  & > option:disabled {
    color: var(--matterColorAnti);
  }
}

.selectSuccess {
  color: var(--matterColor);
  border-bottom-color: var(--successColor);
}

.selectError {
  border-bottom-color: var(--failColor);
}
