@import '../../styles/propertySets.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* 113 for footer and an extra 67 to get scrolling work in mobile safari */
  margin-bottom: 180px;
  text-align: center;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  display: flex;
  align-items: stretch;
  flex-direction: column;
  gap: 8px;
}

.avatar {
  /* Layout */
  flex-shrink: 0;
  margin: var(--TopbarMobileMenu_topMargin) 0 0 0;
}

.greeting {
  /* Font */
  @apply --marketplaceH1FontStyles;
  margin-bottom: 1px;
  margin-top: 16px;
}

.logoutButton {
  @apply --marketplaceLinkStyles;
  /* Logout font is smaller and gray since the action is not recommended. */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  /* Position component */
  width: initial;
  margin: 4px 0 6px 0;
  padding: 0;
}

.inbox {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColor);
  position: relative;

  margin-top: auto;
  margin-bottom: 13px;
}

.navigationLink {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColor);

  /* Layout */
  margin-top: 0;
  margin-bottom: 11px;
}
.currentPageLink {
  color: var(--marketplaceColorDark);
}

.notificationBadge {
  position: absolute;
  top: 4px;
  right: -26px;

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.createNewListingLink {
  @apply --marketplaceButtonStyles;
}

.authenticationGreeting {
  /* Font */
  @apply --marketplaceH1FontStyles;
  width: 100%;

  margin-bottom: 24px;
  margin-top: var(--TopbarMobileMenu_topMargin);
}
.authenticationLinks {
  /* white-space: nowrap; */
  white-space: pre-wrap;
  display: flex;
  align-items: center;
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
  border: 2px solid var(--marketplaceColor);
  padding: 4px 12px;
  border-radius: 50px;
  display: inline-block; /*added*/
  margin: 5px 0; /*added*/
  width: 130px;
  text-align: center;
}
.loginLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
  border: 2px solid var(--marketplaceColor);
  padding: 4px 12px;
  border-radius: 50px;
  display: inline-block; /*added*/
  margin: 5px 0; /*added*/
  width: 130px;
  text-align: center;
}

.currentPage {
  color: var(--matterColorDark);

  /* black left border */
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px;
}

.menu {
  margin-top: 32px;
  margin-bottom: 32px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 260px;
}

.menuItem {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 12px;
}

.primaryButton {
  font-size: 16px;
  border: 1px solid var(--brand-500);
  background-color: var(--brand-500);
  font-weight: var(--fontWeightSemiBold);
  border-radius: 8px;
  height: 42px;
  padding: 14px 20px 14px 20px;
  color: var(--matterColorLight);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.primaryOutlineButton {
  font-size: 16px;
  border: 1px solid var(--gray-300);
  background-color: white;
  font-weight: var(--fontWeightSemiBold);
  border-radius: 8px;
  height: 42px;
  padding: 14px 20px 14px 20px;
  color: var(--primary700);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 16px 0;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}

.langOptionLink {
  @apply --marketplaceH4FontStyles;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  transition: var(--transitionStyleButton);
  text-transform: uppercase;

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logo {
  /* If height is increased, change link paddings accordingly */
  height: 44px;
  /* transform: scale(2); */

  /* Good aspect ratio should be less than 160/27 (width/height) */
  /* NOTE: extremely wide logos, need special handling. */
  width: auto;
  max-width: 220px;
  object-fit: contain;
  object-position: left center;
}
