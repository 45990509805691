.root {
  padding: 12px 0px;
  padding-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.stepContainer {
  /* background: var(--brand-500); */
  background: #f5f5f5;
  border: 2px solid var(--brand-500);
  color: var(--brand-500);
  /* color: white; */
  border-radius: 30px;
  padding: 2px 16px;
  font-weight: 600;
  white-space: nowrap;
  font-size: 16px;

  @media screen and (max-width: 768px) {
    padding: 2px 8px;
    font-size: 14px;
  }
}

.header {
  font-size: 20px;
  font-weight: 400;
  color: black;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
}
