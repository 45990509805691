.container {
  position: relative;
}

.headerWrapper {
  position: relative;
}

.header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.mainMenu {
  display: block;
}

.menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.item {
  position: relative;
}

.itemContent {
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.itemContent:hover {
  background-color: var(--green100);
}

.subMenuWrapper {
  padding-left: 1rem;
  border-left: 2px solid #ddd;
  margin-top: 0.5rem;
}