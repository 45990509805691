.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  padding-top: 1px;
}

.title {
  margin-bottom: 24px;
}

.error {
  color: var(--failColor);
}

.calendarWrapper {
  flex-grow: 1;
  position: relative;
  width: 100%;
  margin-bottom: 24px;
  zoom: 0.7;
  -moz-transform: scale(0.7);
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;
}

@media (--viewportMedium) {
  .root {
    padding-top: 2px;
    margin-top: -16px;
  }

  .title {
    margin-bottom: 32px;
  }
}

@media (--viewportLarge) {
  .calendarWrapper {
    flex-grow: 0;
    margin-bottom: 24px;
  }

  .submitButton {
    display: inline-block;
    width: 241px;
    margin-top: 86px;
  }
}

.dogsCountWrapper {
  margin-top: 30px;
  margin-bottom: 60px;
  padding: 25px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* font shadow */
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.dogsCountLabel {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
}

.buttonsWrapper {
  display: flex;
  margin-top: 20px;

  & label {
    height: 60px;
    width: 80px;
    border: 1px solid #D0D0D0;
    cursor: pointer;
    color: #D0D0D0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;

    &.selected {
      border-color: #1EAD52;
      color: #1EAD52;
    }

    &:first-child {
      border-radius: 10px 0 0 10px;
    }

    &:last-child {
      border-radius: 0 10px 10px 0;
    }
  }

  & input {
    display: none;
  }
}
